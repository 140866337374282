<template>
	<div>
        <v-btn small color="primary" block rounded @click="$refs.file.click()" :loading="cargando">
            <v-icon left>mdi-camera</v-icon>Cambiar avatar
        </v-btn>
	    <input type="file" ref="file" accept="image/*" style="display: none" @change="guardar()">
        
        <v-expand-transition>
            <v-alert v-if="alert" type="success" dense text class="mt-3 rounded-xl">
                <span class="font-weight-bold">avatar actualizada</span>
            </v-alert>
        </v-expand-transition>
	</div>
</template>

<script>
import axios from 'axios'
export default {
    data:() => ({
        alert: false,
        cargando: false
    }),
    methods: {
        async guardar() {
            // this.alert = true
            // setTimeout(() => {
            //     this.alert = false
            //     location.reload()
            // }, 3000);
            this.cargando = true

            let formData = new FormData();
            formData.append('avatar', this.$refs.file.files[0])
            formData.append('user_id', this.$store.state.user.id)
            // formData.append('_method', 'PUT')
            await axios.post(`/api/foto`, formData, {
                headers: { Authorization: `Bearer ${this.$store.state.token}` }
            })
            .then((response) => {
            	location.reload()
            }).catch((error) => {
            	console.error(error);
            })
            this.cargando = false
        }
    }
}
</script>