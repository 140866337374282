import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        isAuthenticated: false,
        token: null,
        cuestionario: null,
        contrincante: null,
        materiales: [],
        evaluaciones: [],
        realizados: [],
        pendientes: [],
        favoritos: [],
        vistos: [],
        retas: [],
        retados: [],
        lineas: [],
    },
    getters: {},
    mutations: {
        SET_USER(state, user) {
            state.user = user.user
            state.isAuthenticated = true
            state.materiales = user.materiales;
            state.evaluaciones = user.evaluaciones;
            state.realizados = user.realizados;
            state.pendientes = user.pendientes;
            state.favoritos = user.favoritos;
            state.vistos = user.vistos;
            state.retas = user.retas;
            state.retados = user.retados;
            state.lineas = user.lineas;
            // state.cuestionario = user.cuestionario;
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_LOGOUT(state) {
            state.user = null;
            state.isAuthenticated = false;
            state.token = null;
        },
        SET_PLAYER(state, id) {
            state.player = state.materiales.find(material => material.id == id)
        },
        SET_FAVORITO(state, favorito) {
            if (favorito) {
                let index = state.favoritos.findIndex(material => material.id == state.player.id);
                state.favoritos.splice(index, 1);
            } else {
                state.favoritos.push(state.player)
            }
        },
        SET_VISTO(state, id) {
            let index = state.vistos.findIndex(id => id == id);
            if (index) {
                state.vistos.push(id)
            }
        },
        SET_PUNTOS(state, puntos) {
            switch (puntos.tipo) {
                case 'visto':
                    state.user.puntos += parseFloat(puntos.puntos);
                    break;
                case 'evaluacion':
                    if (puntos.puntos >= 9) {
                        let index = state.pendientes.findIndex(material => material.id == puntos.id);
                        state.realizados.push(state.player)
                        state.pendientes.splice(index, 1);
                    }
                    state.player = null
                    break;
                default:
                    if (puntos.juego) {
                        let index = state.retas.findIndex(reta => reta.juego == puntos.juego);
                        state.retas.splice(index, 1);
                    } 
                    state.user.puntos += parseFloat(puntos.puntos);
                    break;
            }
            // if (puntos.tipo = 'visto') {
            //     state.user.puntos += parseFloat(puntos.puntos);
            // } else if (puntos.tipo == 'evaluacion') {
            //     if (puntos.puntos == 10) {
            //             let index = state.pendientes.findIndex(material => material.id == puntos.id);
            //             state.realizados.push(state.player)
            //             state.pendientes.splice(index, 1);
            //         }
            //         state.player = null
            // } else {
            //     if (puntos.juego) {
            //         let reta = state.retas.find(reta => reta.juego == puntos.juego);
            //         let index = state.retas.findIndex(reta => reta.id == puntos.id);
            //         state.retas.splice(index, 1);
            //     } 
            //     state.user.puntos += parseFloat(puntos.puntos);
            // }
        },
        SET_CUESTIONARIO(state, cuestionario) {
            state.cuestionario = cuestionario
        },
        SET_CONTRINCANTE(state, contrincante) {
            state.contrincante = contrincante
        },
    },
    actions: {
        // async registro({ commit }, credenciales) {
        //     try {
        //         let response = await axios.post("/register", credenciales);
        //         commit("SET_USER", response.data.user);
        //         commit("SET_TOKEN", response.data.token);
        //         localStorage.setItem("token", response.data.token);
        //         return { success: true };
        //     } catch (error) {    
        //         console.error(error)
        //         return { success: false, message: error.response.data.message };
        //     }
        // },
        async login({ commit }, credenciales) {
            try {
                let response = await axios.post("/login", credenciales);
                commit("SET_USER", response.data);
                commit("SET_TOKEN", response.data.token);
                localStorage.setItem("token", response.data.token);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async getUser({ commit }, token) {
            try {
                let response = await axios.get("/api/user", {
                    headers: { Authorization: `Bearer ${token}` }
                });
                commit("SET_USER", response.data);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async logout({ commit, state }) {
            try {
                let response = await axios.post("/logout", {}, {
                    headers: { Authorization: `Bearer ${state.token}` }
                });
                commit("SET_LOGOUT", response.data);
                localStorage.removeItem("token");
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
    },
    modules: {},
});
