<template>
    <div class="mt-3" v-if="mostrar">
        <v-divider></v-divider>
        <v-subheader class="justify-center">Gerentes</v-subheader>
        <v-select :items="lineas" item-text="nombre" item-value="id" :value="$store.state.user.linea_id" color="primary"
            label="Cambiar línea" rounded filled dense @change="cambiarLinea" :loading="cargando"></v-select>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        cargando: false,
        lineas: [
            { id: 1, nombre: "CARDIO" },
            { id: 2, nombre: "CARDIO - DIABETES" }
        ]
    }),
    computed: {
        mostrar() {
            const emails = [
                'ejimeneza@silanes.com.mx',
                'cvilchis@silanes.com.mx',
                'abesquivel@silanes.com.mx',
                'ugrados@silanes.com.mx',
                'nslopez@silanes.com.mx',
                'reperez@silanes.com.mx',
                'rgandara@silanes.com.mx',
                'glopez@silanes.com.mx',
                'gruiz@silanes.com.mx',
                'amartinezl@silanes.com.mx',
                'cabernal@silanes.com.mx',
                'acsotelo@silanes.com.mx',
                'jaguilar@silanes.com.mx',
                'nlpliego@silanes.com.mx',
                'letorres@silanes.com.mx',
                'mavasquez@silanes.com.mx',
                'anunezs@silanes.com.mx',
                'mjhernandez@silanes.com.mx',
                'darius_le3@live.com.mx',
                'esotelo@glowmedia.com.mx'
            ]
            if (emails.includes(this.$store.state.user.email)) {
                return true
            }
            return false
        }
    },
    methods: {
        cambiarLinea(id) {
            this.cargando = true
            axios.post('/api/cambiar-linea', {
                linea_id: id
            }, {
                headers: { Authorization: `Bearer ${this.$store.state.token}` }
            })
            .then((response) => {
                location.reload()
            })
            .catch((error) => {
                console.error(error)
            })
        }
    }
}
</script>

<style>

</style>