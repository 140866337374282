<template>
    <v-app>
        <v-app-bar app flat :color="bg" v-if="$store.state.isAuthenticated">
            <div class="d-flex align-center">
                <v-img :alt="$appName" class="shrink mr-2" contain src="@/assets/silangames-logo-horizontal-blanco.png"
                    transition="scale-transition" width="120" />
            </div>
            <v-spacer></v-spacer>
            <v-btn icon large exact :to="{ name: 'descargas' }">
                <v-icon>mdi-folder-download-outline</v-icon>
            </v-btn>
            <v-icon color="success" v-if="$store.state.pendientes.length == 0">mdi-checkbox-marked</v-icon>
            <v-menu offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large color="white" v-bind="attrs" v-on="on">
                        <v-badge :content="$store.state.pendientes.length" :value="$store.state.pendientes.length"
                            color="red" overlap>
                            <v-icon>mdi-bell-outline</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list dense color="secondary">
                    <v-list-item dense v-for="material in $store.state.pendientes" :key="material.id"
                        :to="{ name: 'cuestionario', params: { tipo: 'evaluacion', id: material.id } }">
                        <v-list-item-title>{{ material.nombre }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- <v-btn fab x-small color="white" exact :to="{ name: 'favoritos' }">
                <v-icon color="grey">mdi-heart-outline</v-icon>
            </v-btn> -->
            <v-btn icon large color="white" exact :to="{ name: 'favoritos' }">
                <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <!-- <v-btn color="white" outlined rounded @click="logout()">Cerrar sesion</v-btn> -->
        </v-app-bar>

        <v-navigation-drawer app v-model="drawer" color="rgba(51, 51, 51, 0.925)" v-if="$store.state.isAuthenticated">

            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar color="primary" class="elevation-10">
                        <v-img :src="$store.state.user.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $store.state.user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <div class="pa-3">
                <v-divider></v-divider>
                <foto></foto>
                <v-divider class="mt-3"></v-divider>
                <v-subheader class="justify-center">Biblioteca</v-subheader>
                <v-btn block color="primary" class="text-white" rounded small
                    :to="{ name: 'categoria' }">Capacitación Continua</v-btn>
            </div>

            <Gerentes />

            <template v-if="$store.state.user.rol == 'admin'">
                <v-divider></v-divider>
                <div class="px-3">
                    <v-subheader class="justify-center">Área de administrador</v-subheader>
                    <v-btn color="primary" rounded small block :to="{ name: 'lineas' }">Líneas</v-btn>
                    <cambiar-linea></cambiar-linea>
                </div>
            </template>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block color="primary" class="text-white" outlined @click="logout()">Cerrar sesión</v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-main>
            <router-view :key="$route.fullPath" />
        </v-main>

        <v-footer app class="justify-space-between" color="secondary" v-if="$store.state.isAuthenticated">
            <v-btn icon x-large exact :to="{ name: 'home' }">
                <v-tooltip top left :value="descripcionIcono" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-home-variant-outline</v-icon>
                    </template>
                    Inicio
                </v-tooltip>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'cuestionario', params: { tipo: 'juego' } }">
                <v-tooltip top left :value="descripcionIcono" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-gamepad-variant-outline</v-icon>
                    </template>
                    Juegos
                </v-tooltip>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'posiciones' }">
                <v-tooltip top left :value="descripcionIcono" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-trophy-outline</v-icon>
                    </template>
                    Posiciones
                </v-tooltip>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'cuestionario', params: { tipo: 'reta' } }">
                <v-tooltip top left :value="descripcionIcono" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-account-group-outline</v-icon>
                    </template>
                    Reta
                </v-tooltip>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'estadisticas' }">
                <v-tooltip top left :value="descripcionIcono" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-chart-line</v-icon>
                    </template>
                    Estadísticas
                </v-tooltip>
            </v-btn>
        </v-footer>
    </v-app>
</template>

<script>
import CambiarLinea from './components/CambiarLinea.vue';
import Gerentes from './components/Gerentes.vue';
import Foto from './components/Foto.vue';
export default {
    components: { Foto, CambiarLinea, Gerentes },
    data: () => ({
        drawer: false,
        descripcionIcono: true,
        bg: 'transparent',
    }),
    methods: {
        async logout() {
            const response = await this.$store.dispatch('logout')

            if (response.success) {
                console.log("logout exitoso");
                this.drawer = false
                this.$router.push({ name: "welcome" });
            } else {
                console.log("Error en el logout");
            }
        },
        changeColor() {
            if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
                this.bg = 'secondary';
            } else {
                this.bg = 'transparent';
            }
        },
    },
    async created() {
        const token = localStorage.getItem('token');
        if (token && token != 'undefined') {
            console.log(token);
            this.$store.commit('SET_TOKEN', token);

            const response = await this.$store.dispatch('getUser', token)

            if (response.success && this.$route.name != 'home') {
                console.log("token exitoso");
                this.$router.push({ name: 'home' });
            } else {
                console.log("Error en el token");
            }
        }
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
        setTimeout(() => {
            this.descripcionIcono = false
        }, 5000);
    }
};
</script>

<style lang="scss" scoped>
#app.theme--dark {
    background: {
        image: url('@/assets/fondo.jpg');
        size: cover;
        position: center;
        repeat: no-repeat;
        attachment: fixed;
    }
}
.v-tooltip__content {
    padding: 5px 10px;
}
</style>