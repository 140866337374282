import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

import './main.scss'

import mixin from "./mixins";
Vue.mixin(mixin);

// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = "https://servidor.silangames.mx/";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.get('/sanctum/csrf-cookie').then(response => {});

Vue.config.productionTip = false
Vue.prototype.$appName = process.env.VUE_APP_SITE_NAME;
Vue.prototype.$backendUrl = axios.defaults.baseURL;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
