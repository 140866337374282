<template>
    <v-select :items="$store.state.lineas" item-text="nombre" item-value="id" :value="$store.state.user.linea_id" color="primary" label="Cambiar línea" rounded filled dense class="mt-5" @change="cambiarLinea" :loading="cargando"></v-select>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        cargando: false,
    }),
    methods: {
        cambiarLinea(id) {
            this.cargando = true
            axios.post('/api/cambiar-linea', {
                linea_id: id
            }, {
                headers: { Authorization: `Bearer ${this.$store.state.token}` }
            })
            .then((response) => {
                location.reload()
            })
            .catch((error) => {
                console.error(error)
            })
        }
    }
}
</script>

<style>

</style>